/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);


        // Scroll Up Button
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });


        // Testimonial Slider
        $('.testimonial-slider').slick({
          infinite: true,
          arrows: false,
          dots: true,
          appendDots:$(".slick-nav"),
          slidesToShow: 1,
          slidesToScroll: 1
        });

        $('.left').click(function(){
          $('.testimonial-slider').slick('slickPrev');
        });

        $('.right').click(function(){
          $('.testimonial-slider').slick('slickNext');
        });


        // Our Client Slider
        $('.client-slider').slick({
          infinite: true,
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 1,
          nextArrow: '<i class="fa fa fa-caret-right arrow right"></i>',
          prevArrow: '<i class="fa fa fa-caret-left arrow left"></i>',
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        });


        // Partner Slider
        $('.partner-slider').slick({
          infinite: true,
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 1,
          nextArrow: '<i class="fa fa fa-caret-right arrow right"></i>',
          prevArrow: '<i class="fa fa fa-caret-left arrow left"></i>',
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        });





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact page

        // var geocoder;
        // var map;
        // var bound = new google.maps.LatLngBounds();
        // function initialize_gmap() {
        //   geocoder = new google.maps.Geocoder();
        //   var latlng = new google.maps.LatLng(-45.2538, 69.4455);
        //   var myOptions = {
        //     zoom: 15,
        //     center: latlng,
        //     scrollwheel: false,
        //     mapTypeControl: false,
        //     navigationControl: false,
        //     navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
        //     mapTypeId: google.maps.MapTypeId.ROADMAP
        //   };
        //   map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
        // }
        //
        // function geocode(address, title) {
        //   if (geocoder) {
        //     geocoder.geocode({
        //       'address': address
        //     }, function(results, status) {
        //       if (status === google.maps.GeocoderStatus.OK) {
        //         if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
        //           bound.extend(results[0].geometry.location);
        //           map.setCenter(bound.getCenter());
        //           var infowindow = new google.maps.InfoWindow({
        //             content: '<h2>' + title + '<br>' +
        //             '<small><a href="https://www.google.com/maps/dir/?api=1&destination=' + results[0].geometry.location + '" target="_blank">Directions</a></small></h2>' +
        //             '<strong>' + address + '</strong>',
        //             size: new google.maps.Size(150, 50)
        //           });
        //           var marker = new google.maps.Marker({
        //             position: results[0].geometry.location,
        //             map: map,
        //             title: address
        //           });
        //           google.maps.event.addListener(marker, 'click', function() {
        //             infowindow.open(map, marker);
        //           });
        //         } else {
        //           alert("No results found");
        //         }
        //       } else {
        //         $('#map_canvas').remove();
        //       }
        //     });
        //   }
        //   }
        //
        //   var locations = [];
        //   $('.location-map').each(function() {
        //     var location = [];
        //     location.push($(this).find('.map-data').find('.address').data('address'));
        //     location.push($(this).find('.map-data').find('.title').data('title'));
        //     locations.push(location);
        //   });
        //
        //   if($('#map_canvas').length) {
        //     initialize_gmap();
        //     for (i = 0; i < locations.length; i++) {
        //       geocode(locations[i][0], locations[i][1]);
        //     }
        //   }
      }
    },
    'single_portfolio': {
      init: function() {
        // JavaScript to be fired on the about us page


        $('.photo-gallery').lightGallery({
            thumbnail:true,
            selector: '.gallery-item'

        });

        $('#launchGallery').click(function(){
            $('#firstImage').trigger('click');
        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
